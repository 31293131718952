import {
  CheckboxComponent,
  DropdownOption,
  InputComponent,
  PopupComponent,
} from "deinestadtliebt-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiscountType } from "../../../utils/action/Action.types";
import { ActivityType } from "../../../utils/activity/Activity.types";
import {
  checkCertainPropOfCompanion,
  RequiredCompanionProps,
} from "../../../utils/activity/ActivityUtils";
import { PriceCategory, TicketType } from "../../../utils/event/Event.types";
import {
  generateCorrectInputsForActionType,
  generateCorrectPriceCategoryDropdownList,
  generateCorrectPriceCategoryList,
  generateOnlineTicketInputs,
  generateTicketPriceInputs,
  generateVVKTicketInputs,
} from "../../../utils/event/EventUtils";
import {
  createEmptyVVKLocation,
  Provider,
  VVKLocation,
} from "../../../utils/user/User.types";
import { ActivityEditCompanion } from "../EventEditBoxComponent.types";

interface Step4Props {
  isDesktop: boolean;
  isLoading: boolean;
  provider: Provider;
  activityCompanion: ActivityEditCompanion;
  setActivityCompanion(activityCompanion: ActivityEditCompanion): void;
  stepCompletion?: boolean;
}

const ActivityStep4: React.FC<Step4Props> = ({
  isDesktop,
  isLoading,
  provider,
  activityCompanion,
  setActivityCompanion,
  stepCompletion,
}) => {
  const { t } = useTranslation();
  const [dropdownBookingLocations, setDropdownBookingLocations] = useState<
    DropdownOption[]
  >([]);
  const [currentVvkLocation, setCurrentVvkLocation] = useState<VVKLocation>(
    createEmptyVVKLocation()
  );
  const [vvkLocationPopupToggle, setvvkLocationPopupToggle] =
    useState<boolean>(false);
  const [selectedVvkLocationOption, setSelectedVvkLocationOption] =
    useState<string>("-1");

  const [selectedPriceCategoryDropdown, setSelectedPriceCategoryDropdown] =
    useState<
      | "DEFAULT"
      | "ADULT"
      | "KIDS"
      | "REDUCED"
      | "SENIOR"
      | "KIDS"
      | "HANDICAPPED"
      | string
    >("DEFAULT");
  const [ticketPricePopupToggle, setTicketPricePopupToggle] =
    useState<boolean>(false);

  const [priceCategoryList, setPriceCategoryList] = useState<PriceCategory[]>(
    generateCorrectPriceCategoryList(activityCompanion)
  );
  const [selectedPriceCategory, setSelectedPriceCategory] =
    useState<PriceCategory>(priceCategoryList[0]);
  const [priceCategoryDropdownOptions, setPriceCategoryDropdownOptions] =
    useState<DropdownOption[]>(
      generateCorrectPriceCategoryDropdownList(priceCategoryList)
    );

  /**
   * Helper to set properties when price category is selected
   * @param value string that contains the value of the dropdown
   */
  const selectPriceCategory = (value: string): void => {
    setSelectedPriceCategoryDropdown(value);
    priceCategoryList.forEach((priceCategory) => {
      if (value === priceCategory.id) setSelectedPriceCategory(priceCategory);
    });
  };

  /**
   * Helper to change priceCategoryList in companion when localPriceList is changed
   */
  useEffect(() => {
    setActivityCompanion({
      ...activityCompanion,
      priceCategoryList: priceCategoryList,
    });
    // eslint-disable-next-line
  }, [priceCategoryList]);

  /**
   * Helper to assign changed values to priceCategoryList
   */
  useEffect(() => {
    let localPriceList: PriceCategory[] = [...priceCategoryList];
    // eslint-disable-next-line
    priceCategoryList.map((priceCategory, pcIndex) => {
      if (priceCategory.id === selectedPriceCategory.id)
        localPriceList[pcIndex] = selectedPriceCategory;
    });
    setPriceCategoryList(localPriceList);
    // eslint-disable-next-line
  }, [selectedPriceCategory]);

  /**
   * Helper to fill link-list with empty values until it has the same length as dateList
   */
  useEffect(() => {
    let localLinkList: string[] = [
      ...(activityCompanion.event.dateLinkList || []),
    ];
    if (
      localLinkList.length > activityCompanion.dateList.length &&
      !activityCompanion.singleDateLink
    )
      localLinkList = localLinkList.slice(0, activityCompanion.dateList.length);
    while (localLinkList.length < activityCompanion.dateList.length) {
      localLinkList.push("");
    }
    setActivityCompanion({
      ...activityCompanion,
      event: { ...activityCompanion.event, dateLinkList: localLinkList },
    });
    // eslint-disable-next-line
  }, []);

  /**
   * this useEffect generates an new entry for priceCategories to add new entries
   */
  useEffect(() => {
    let localPriceCatDropdownList: DropdownOption[] = [
      ...priceCategoryDropdownOptions,
    ];
    let localPriceCategories = [...priceCategoryList];

    for (const element of provider.customPriceItemsForActivity) {
      localPriceCatDropdownList.push({
        label: element.name,
        value: element.id,
        disabled: false,
      });
      localPriceCategories.push({
        description: element.description,
        enabled: false,
        id: element.id,
        label: element.name,
        price: element.price,
      });
    }

    localPriceCatDropdownList.push({
      label: (
        <div
          onClick={() => {
            setTicketPricePopupToggle(true);
            setSelectedPriceCategory({
              enabled: false,
              id: "",
              label: "",
              price: 0,
              description: "",
            });
          }}
        >
          {t("adminEventPage.event.steps.step4.addCategory")}
        </div>
      ),
      value: "-1",
    });

    setPriceCategoryDropdownOptions(localPriceCatDropdownList);
    setPriceCategoryList(localPriceCategories);
    // eslint-disable-next-line
  }, []);

  /**
   * this useEffect generates the event location dropdown contents.
   */
  useEffect(() => {
    let localDropdownLocations: DropdownOption[] = [];
    if (provider?.advanceBookingOffices.length > 0) {
      provider.advanceBookingOffices.forEach((item) =>
        localDropdownLocations.push({
          label: `${item.name} (${item.location.street}, ${item.location.zipCode} ${item.location.city})`,
          value: item.id,
        })
      );
    }
    localDropdownLocations.push({
      label: (
        <div
          onClick={() => {
            setvvkLocationPopupToggle(true);
            setCurrentVvkLocation(createEmptyVVKLocation());
          }}
        >
          {t("providerEventsConfigurationPage.dropdownDefaultEntry")}
        </div>
      ),
      value: "-1",
    });
    setDropdownBookingLocations([...localDropdownLocations]);

    // eslint-disable-next-line
  }, []);

  /**
   * UseEffect to assign correct eventLocation to currentVvkLocation based on selected value from dropdown
   */
  useEffect(() => {
    const foundVvkLocation: VVKLocation =
      provider.advanceBookingOffices.filter(
        (item) => item.id === selectedVvkLocationOption
      )[0] || createEmptyVVKLocation();

    setCurrentVvkLocation(foundVvkLocation);
    // eslint-disable-next-line
  }, [selectedVvkLocationOption]);

  /**
   * Helper to add entry to vvkLocationList
   */
  const addEntryToVvkList = () => {
    let localVvkList: VVKLocation[] = [];
    if (!!activityCompanion.event.vvkList)
      localVvkList = [...activityCompanion.event.vvkList];
    localVvkList.push(currentVvkLocation);
    setActivityCompanion({
      ...activityCompanion,
      event: { ...activityCompanion.event, vvkList: localVvkList },
    });
  };

  /**
   * Helper to delete entry from vvkLocation
   * @param locationIndex index of location to be deleted
   */
  const deleteEntryFromVvkList = (locationIndex: number) => {
    const localVvkList = [...activityCompanion.event.vvkList!];
    localVvkList.splice(locationIndex, 1);
    setActivityCompanion({
      ...activityCompanion,
      event: { ...activityCompanion.event, vvkList: localVvkList },
    });
  };

  /**
   * Method to disable specific entry in priceCategoryList
   * @param index index of entry to be disabled
   */
  const deactiveEntryInPriceList = (index: number) => {
    const localPriceCatList = [...activityCompanion.priceCategoryList];
    localPriceCatList[index].enabled = false;
    setPriceCategoryList(localPriceCatList);
  };

  /**
   * Helper to handle change of TicketType
   * @param value value of ticketType to be changed
   */
  const handleTicketTypeSelect = (value: TicketType): void => {
    let selectedTicketTypes: TicketType[] = [
      ...activityCompanion.event.ticketType,
    ];
    if (selectedTicketTypes.includes(value)) {
      selectedTicketTypes = selectedTicketTypes.filter((TT) => TT !== value);
    } else {
      if (value === TicketType.FREE) selectedTicketTypes = [];
      else
        selectedTicketTypes = selectedTicketTypes.filter(
          (TT) => TT !== TicketType.FREE
        );
      selectedTicketTypes.push(value);
    }
    setActivityCompanion({
      ...activityCompanion,
      event: {
        ...activityCompanion.event,
        ticketType: selectedTicketTypes,
      },
    });
  };

  /**
   * Helper to toggle Popup via button press when manual-input is set
   */
  const togglePopupFromInside = () => {
    setvvkLocationPopupToggle(true);
    setCurrentVvkLocation(createEmptyVVKLocation());
  };

  /**
   * Helper to create a new entry for priceCategoryList and also adds a new entry to the dropdownOptions for it
   */
  const createAndSaveNewPriceCategory = () => {
    let localPriceCatList = [...priceCategoryList];
    let localPriceCatDropdownList = [...priceCategoryDropdownOptions];
    localPriceCatList.push(selectedPriceCategory);
    localPriceCatDropdownList.push({
      label: selectedPriceCategory.label,
      value: selectedPriceCategory.label,
    });
    setSelectedPriceCategoryDropdown(selectedPriceCategory.label);
    setPriceCategoryList(localPriceCatList);
    setPriceCategoryDropdownOptions(localPriceCatDropdownList);
    setTicketPricePopupToggle(false);
  };

  return (
    <>
      <PopupComponent
        title={t("adminEventPage.event.steps.step4.newCategory")}
        open={ticketPricePopupToggle}
        toggleOpen={() => setTicketPricePopupToggle(false)}
        bottomButtons={[
          {
            value: t("adminEventPage.event.steps.step4.addCategory"),
            onClick: () => createAndSaveNewPriceCategory(),
          },
        ]}
      >
        <div className="input-label">
          {t("adminEventPage.event.steps.step4.nameOfCategory")}
        </div>
        <InputComponent
          isFrameless
          disabled={isLoading}
          value={selectedPriceCategory.label ?? ""}
          onChange={(value) =>
            setSelectedPriceCategory({
              ...selectedPriceCategory,
              label: value,
              id: value,
            })
          }
          type="text"
          placeholder={t("adminEventPage.event.steps.step4.nameOfCategory")}
        />
        <div className="input-label">
          {t("adminEventPage.event.steps.step4.catPrice")}
        </div>
        <InputComponent
          decimals={2}
          isFrameless
          disabled={isLoading}
          value={selectedPriceCategory.price ?? 0}
          onChange={(value) =>
            setSelectedPriceCategory({
              ...selectedPriceCategory,
              price: parseFloat(value),
            })
          }
          type="number"
          placeholder={t("adminEventPage.event.steps.step4.catPrice")}
        />
      </PopupComponent>
      <PopupComponent
        title={t("adminEventPage.event.steps.step4.newLocation")}
        open={vvkLocationPopupToggle}
        toggleOpen={() => setvvkLocationPopupToggle(false)}
        bottomButtons={[
          {
            value: t("adminEventPage.event.steps.step4.saveLocation"),
            onClick: () => {
              addEntryToVvkList();
              setvvkLocationPopupToggle(false);
            },
          },
        ]}
      >
        <div className="selected-content--location-manualInput">
          <InputComponent
            disabled={isLoading}
            isFrameless
            value={currentVvkLocation?.name ?? ""}
            onChange={(value) =>
              setCurrentVvkLocation({
                ...currentVvkLocation!,
                name: value,
              })
            }
            placeholder={t("providerEventsConfigurationPage.inputs.name")}
          />
          <InputComponent
            disabled={isLoading}
            isFrameless
            value={currentVvkLocation?.location.street ?? ""}
            onChange={(value) =>
              setCurrentVvkLocation({
                ...currentVvkLocation!,
                location: {
                  ...currentVvkLocation!.location,
                  street: value,
                },
              })
            }
            placeholder={t("providerEventsConfigurationPage.inputs.street")}
          />
          <div className="flex-it">
            <InputComponent
              isFrameless
              disabled={isLoading}
              value={currentVvkLocation?.location.zipCode ?? ""}
              onChange={(value) =>
                setCurrentVvkLocation({
                  ...currentVvkLocation!,
                  location: {
                    ...currentVvkLocation!.location,
                    zipCode: value,
                  },
                })
              }
              placeholder={t("providerEventsConfigurationPage.inputs.zipCode")}
            />
            <InputComponent
              isFrameless
              disabled={isLoading}
              value={currentVvkLocation?.location.city ?? ""}
              onChange={(value) =>
                setCurrentVvkLocation({
                  ...currentVvkLocation!,
                  location: {
                    ...currentVvkLocation!.location,
                    city: value,
                  },
                })
              }
              placeholder={t("providerEventsConfigurationPage.inputs.city")}
            />
          </div>
          <div>
            <InputComponent
              isFrameless
              disabled={isLoading}
              value={currentVvkLocation?.mail ?? ""}
              onChange={(value) =>
                setCurrentVvkLocation({
                  ...currentVvkLocation!,
                  mail: value,
                })
              }
              placeholder={t("providerEventsConfigurationPage.inputs.mail")}
            />
            <InputComponent
              isFrameless
              disabled={isLoading}
              value={currentVvkLocation?.phone ?? ""}
              onChange={(value) =>
                setCurrentVvkLocation({
                  ...currentVvkLocation!,
                  phone: value,
                })
              }
              placeholder={t("providerEventsConfigurationPage.inputs.phone")}
            />
            <InputComponent
              isFrameless
              disabled={isLoading}
              value={currentVvkLocation?.website ?? ""}
              onChange={(value) =>
                setCurrentVvkLocation({
                  ...currentVvkLocation!,
                  website: value,
                })
              }
              placeholder={t("providerEventsConfigurationPage.inputs.website")}
            />
          </div>
        </div>
      </PopupComponent>
      {activityCompanion.activityType === ActivityType.EVENT ? (
        <>
          {isDesktop && <h2>{t("adminEventPage.event.steps.step4Title")}</h2>}
          <div className="input-label">
            {t("adminEventPage.event.steps.step4.ticketAvailable")}
          </div>
          <div className="selected-content--ticket-type">
            <CheckboxComponent
              checked={activityCompanion.event.ticketType.includes(
                TicketType.ONLINE
              )}
              value={t("adminEventPage.event.steps.step4.ticketsOnline")}
              onCheck={() => handleTicketTypeSelect(TicketType.ONLINE)}
            />
            <CheckboxComponent
              checked={activityCompanion.event.ticketType.includes(
                TicketType.VVK
              )}
              value={t("adminEventPage.event.steps.step4.ticketsVvk")}
              onCheck={() => handleTicketTypeSelect(TicketType.VVK)}
            />
            <CheckboxComponent
              checked={activityCompanion.event.ticketType.includes(
                TicketType.ON_SPOT
              )}
              value={t("adminEventPage.event.steps.step4.ticketsOnSpot")}
              onCheck={() => handleTicketTypeSelect(TicketType.ON_SPOT)}
            />
            <CheckboxComponent
              checked={activityCompanion.event.ticketType.includes(
                TicketType.FREE
              )}
              value={t("adminEventPage.event.steps.step4.ticketsForFree")}
              onCheck={() => handleTicketTypeSelect(TicketType.FREE)}
            />
          </div>
          <div>
            {generateOnlineTicketInputs(
              activityCompanion,
              setActivityCompanion,
              stepCompletion
            )}
            {generateVVKTicketInputs(
              activityCompanion,
              dropdownBookingLocations,
              selectedVvkLocationOption,
              setSelectedVvkLocationOption,
              addEntryToVvkList,
              deleteEntryFromVvkList,
              togglePopupFromInside,
              stepCompletion
            )}
            {generateTicketPriceInputs(
              activityCompanion.event.ticketType,
              priceCategoryDropdownOptions,
              selectedPriceCategoryDropdown,
              selectPriceCategory,
              priceCategoryList,
              selectedPriceCategory,
              setSelectedPriceCategory,
              deactiveEntryInPriceList,
              activityCompanion,
              stepCompletion
            )}
          </div>
          <div className="selected-content--register">
            <div className="input-label">
              {t("adminEventPage.event.steps.step4.registrationRequired")}
            </div>
            <CheckboxComponent
              value=""
              checked={activityCompanion?.event?.registration || false}
              onCheck={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  event: {
                    ...activityCompanion.event,
                    registration: !activityCompanion.event.registration,
                  },
                })
              }
            />
          </div>

          {(activityCompanion?.event?.registration || false) && (
            <div>
              <div className="selected-content--register-stop">
                <div className="input-label">
                  {t("adminEventPage.event.steps.step4.registrationStop")}
                </div>
                <InputComponent
                  value={
                    activityCompanion.event.registerDate
                      ? new Date(
                          activityCompanion.event.registerDate
                        ).toISOString()
                      : ""
                  }
                  isFrameless
                  type="date"
                  onChange={(value) =>
                    setActivityCompanion({
                      ...activityCompanion,
                      event: {
                        ...activityCompanion.event,
                        registerDate: value
                          ? new Date(value)
                          : new Date(
                              activityCompanion.event.registerDate || new Date()
                            ),
                      },
                    })
                  }
                />
              </div>
              <p>{t("adminEventPage.event.steps.step4.registrationDetail")}</p>
              <InputComponent
                type="multiLine"
                value={activityCompanion.event.registerDetail ?? ""}
                onChange={(value) =>
                  setActivityCompanion({
                    ...activityCompanion,
                    event: {
                      ...activityCompanion.event,
                      registerDetail: value,
                    },
                  })
                }
              />
            </div>
          )}
        </>
      ) : (
        <>
          {isDesktop && (
            <h2>{t("adminEventPage.event.steps.step4ActionTitle")}</h2>
          )}
          <div className="input-label">
            {t("adminEventPage.event.steps.step4.kindOfDiscount")}
          </div>
          <div className="selected-content--discount-type">
            <CheckboxComponent
              value={t("adminEventPage.event.steps.step4.priceDiscount")}
              checked={
                activityCompanion.action.discountType === DiscountType.PRICE
              }
              onCheck={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  action: {
                    ...activityCompanion.action,
                    discountType: DiscountType.PRICE,
                  },
                })
              }
            />
            <CheckboxComponent
              value={t("adminEventPage.event.steps.step4.percentDiscount")}
              checked={
                activityCompanion.action.discountType === DiscountType.PERCENT
              }
              onCheck={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  action: {
                    ...activityCompanion.action,
                    discountType: DiscountType.PERCENT,
                  },
                })
              }
            />
            <CheckboxComponent
              value={t("adminEventPage.event.steps.step4.specialDiscount")}
              checked={
                activityCompanion.action.discountType === DiscountType.SPECIAL
              }
              onCheck={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  action: {
                    ...activityCompanion.action,
                    discountType: DiscountType.SPECIAL,
                  },
                })
              }
            />
          </div>
          <div>
            {generateCorrectInputsForActionType(
              activityCompanion,
              setActivityCompanion,
              stepCompletion
            )}
          </div>
          <div className="input-label">
            {t("adminEventPage.event.steps.step4.discountDetail")}
            {activityCompanion.action.discountType === DiscountType.SPECIAL && (
              <div className="required">
                {t("adminEventPage.event.requiredField")}
              </div>
            )}
          </div>
          <div className="discount-detail-input">
            <InputComponent
              isValid={
                stepCompletion ||
                checkCertainPropOfCompanion(
                  activityCompanion,
                  RequiredCompanionProps.DISCOUNTDESC
                )
              }
              type="multiLine"
              value={activityCompanion.action.discountDetails || ""}
              onChange={(value) =>
                setActivityCompanion({
                  ...activityCompanion,
                  action: {
                    ...activityCompanion.action,
                    discountDetails: value ? value : "",
                  },
                })
              }
            />
          </div>
          <div className="discount-link--check">
            <CheckboxComponent
              checked={activityCompanion.actionLink}
              value=""
              onCheck={(value) =>
                setActivityCompanion({
                  ...activityCompanion,
                  actionLink: value,
                })
              }
            />
            {t("adminEventPage.discountLinkTitle")}
          </div>
          {activityCompanion.actionLink && (
            <div className="discount-link">
              <div>
                <div className="input-label">
                  {t("adminEventPage.addDiscountLink")}
                </div>
                <InputComponent
                  value={activityCompanion.action.actionLink || ""}
                  onChange={(value) =>
                    setActivityCompanion({
                      ...activityCompanion,
                      action: {
                        ...activityCompanion.action,
                        actionLink: value,
                      },
                    })
                  }
                  isFrameless
                  type="text"
                  placeholder={t("adminEventPage.discountLink")}
                />
              </div>
              <div>
                <div className="input-label">
                  {t("adminEventPage.addDiscountButtonText")}
                </div>
                <InputComponent
                  value={activityCompanion.action.actionLinkText || ""}
                  onChange={(value) =>
                    setActivityCompanion({
                      ...activityCompanion,
                      action: {
                        ...activityCompanion.action,
                        actionLinkText: value,
                      },
                    })
                  }
                  isFrameless
                  type="text"
                  signCap={30}
                  placeholder={t("adminEventPage.discountLinkText")}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ActivityStep4;
