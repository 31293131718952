import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  PopupComponent,
} from "deinestadtliebt-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generateMultiDateTable } from "../../../utils/activity/ActivityUtils";
import {
  DayOfWeekArray,
  EventIntervall,
  TempTimelapse,
} from "../../../utils/event/Event.types";
import { generateDateRows } from "../../../utils/event/EventUtils";
import { generateDateArray } from "../../../utils/time/TimeUtils";
import { DayOfWeek } from "../../../utils/user/User.types";
import { ActivityEditCompanion } from "../EventEditBoxComponent.types";

interface Step3Props {
  isLoading: boolean | undefined;
  isDesktop: boolean;
  activityCompanion: ActivityEditCompanion;
  setActivityCompanion(activityCompanion: ActivityEditCompanion): void;
  stepCompletion?: boolean;
}
const ActivityStep3: React.FC<Step3Props> = ({
  isLoading,
  isDesktop,
  activityCompanion,
  setActivityCompanion,
}) => {
  const { t } = useTranslation();
  const [dateIntervall, setDateIntervall] = useState<EventIntervall>(
    EventIntervall.DAILY
  );
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [dateRepetititonNumber, setDateRepititionNumber] = useState<number>(0);
  const [weekRepetitionNumber, setWeekRepetitionNumber] = useState<number>(1);
  const [weeklyRepititionDay, setWeeklyRepetitionDay] = useState<DayOfWeek[]>(
    []
  );
  const [monthlyRepetitionDay, setMonthlyRepetitionDay] = useState<number>(1);
  const [monthlyRepetitionMonth, setMonthlyRepetitionMonth] =
    useState<number>(1);
  const [specificDateForIntervall, toggleSpecificDateForIntervall] =
    useState<boolean>(false);
  const [dynamicDateDayOfWeekNumber, setDynamicDateDayOfWeekNumber] =
    useState<number>(1);
  const [dynamicDateDayOfWeek, setDynamicDateDayOfWeek] = useState<DayOfWeek>(
    DayOfWeek.MONDAY
  );
  const [yearlyMonth, setYearlyMonth] = useState<number>(0);
  const [dateToEdit, setDateToEdit] = useState<TempTimelapse>();
  const [dateToEditIndex, setDateToEditIndex] = useState<number>();

  /**
   * Helper that copies entry from DateArray and inserts it after copied item
   * @param dateIndex index of item to copy
   * @param copiedEntry entry that will be copied
   */
  const copyEntryFromDateArray = (
    dateIndex: number,
    copiedEntry: TempTimelapse
  ): void => {
    const localDateArray: TempTimelapse[] = [...activityCompanion.dateList];
    localDateArray.splice(dateIndex, 0, copiedEntry);
    setActivityCompanion({ ...activityCompanion, dateList: localDateArray });
  };

  /**
   * Helper to delete entry from date array and trigger rerender
   * @param dateIndex index of the entry that wil lbe removed
   */
  const deleteEntryFromDateArray = (dateIndex: number): void => {
    const localDateArray: TempTimelapse[] = [...activityCompanion.dateList];
    localDateArray.splice(dateIndex, 1);
    setActivityCompanion({ ...activityCompanion, dateList: localDateArray });
  };

  /**
   * Helper to push a new entry into  a givne TempTimeLapse[] to add new entries or duplicate existing ones
   * @param arrayToPushTo TempTimeLapse[] that will get a new entry
   * @param specificTimeLapse optional TempTimeLapse used if a prefilled object will be added (f.e. when copying an existing entry)
   * @returns TempTimeLapse[] with new entry
   */
  const addNewEntryToDateArray = (
    arrayToPushTo: TempTimelapse[],
    specificTimeLapse?: TempTimelapse
  ): TempTimelapse[] => {
    const localArray: TempTimelapse[] = [...arrayToPushTo];
    if (specificTimeLapse) localArray.push(specificTimeLapse);
    else localArray.push(localArray[localArray.length - 1]);
    return localArray;
  };

  /**
   * Helper to set or delete entries from dayOfWeekArray used for date generation
   * @param selectedDayOfWeek DayOfWeek to be added or deleted from array
   */
  const handleDayOfWeekToggle = (selectedDayOfWeek: DayOfWeek) => {
    let localDayOfWeekArray = [...weeklyRepititionDay];
    if (localDayOfWeekArray.includes(selectedDayOfWeek)) {
      localDayOfWeekArray = localDayOfWeekArray.filter((day) => {
        return day !== selectedDayOfWeek;
      });
    } else {
      localDayOfWeekArray.push(selectedDayOfWeek);
    }
    setWeeklyRepetitionDay(localDayOfWeekArray);
  };

  /**
   * Helper to set or delete entries for visibleDays in actions
   * @param selectedDayOfWeek DayOfWeek to be added or deleted from array
   */
  const handleVisibleDaysToggle = (selectedDayOfWeek: DayOfWeek): void => {
    let localDayOfWeekArray = [...activityCompanion.displayedDays];
    if (localDayOfWeekArray.includes(selectedDayOfWeek)) {
      localDayOfWeekArray = localDayOfWeekArray.filter((day) => {
        return day !== selectedDayOfWeek;
      });
    } else {
      localDayOfWeekArray.push(selectedDayOfWeek);
    }
    setActivityCompanion({
      ...activityCompanion,
      displayedDays: localDayOfWeekArray,
    });
  };

  /**
   * Helper to generate the correct additional inputs depending on the given intervall type
   * @param intervall type of intervall
   * @returns JSX.Element containing the generated inputs
   */
  const generateAdditionalInputsForIntervalls = (
    intervall: EventIntervall
  ): JSX.Element => {
    switch (intervall) {
      default:
      case EventIntervall.DAILY:
        return <></>;
      case EventIntervall.WEEKLY:
        return (
          <div className="selected-content--dateRepetition-detail--weeks">
            <div className="selected-content--dateRepetition-detail--intervall">
              <p>{t("adminEventPage.event.dateEdit.repeatsAll")}</p>
              <InputComponent
                value={weekRepetitionNumber ?? ""}
                onChange={(value) => setWeekRepetitionNumber(parseInt(value))}
                type={"number"}
                isFrameless
                max={100}
              />
              <p>{t("adminEventPage.event.dateEdit.weeks")}</p>
            </div>

            <div className="selected-content--dateRepetition-detail--intervall-days">
              <p>{t("adminEventPage.event.dateEdit.repeatsItself")}</p>
              <div className="selected-content--dateRepetition-detail--intervall-days-select">
                {DayOfWeekArray.map((day) => {
                  return (
                    <CheckboxComponent
                      key={day}
                      checked={weeklyRepititionDay.includes(day as DayOfWeek)}
                      onCheck={() => handleDayOfWeekToggle(day as DayOfWeek)}
                      value={t(`enum.dayOfWeekShort.${day}`)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      case EventIntervall.MONTHLY:
        return (
          <>
            <div className="selected-content--dateRepetition-detail--months">
              <CheckboxComponent
                value=""
                checked={specificDateForIntervall}
                onCheck={() => toggleSpecificDateForIntervall(true)}
              />
              <p>{t("adminEventPage.event.dateEdit.repeatsOn")}</p>
              <InputComponent
                type="number"
                value={monthlyRepetitionDay ?? ""}
                onChange={(value) => setMonthlyRepetitionDay(parseInt(value))}
                isFrameless
                min={1}
                max={28}
              />
              <p>{t("adminEventPage.event.dateEdit.eachDay")}</p>
              <InputComponent
                type="number"
                value={monthlyRepetitionMonth ?? ""}
                onChange={(value) => setMonthlyRepetitionMonth(parseInt(value))}
                isFrameless
                min={1}
                max={99}
              />
              <p>{t("adminEventPage.event.dateEdit.months")}</p>
            </div>
            <div className="selected-content--dateRepetition-detail--months selected-content--dateRepetition-detail--months-borderBottom">
              <CheckboxComponent
                value=""
                checked={!specificDateForIntervall}
                onCheck={() => toggleSpecificDateForIntervall(false)}
              />
              <p>{t("adminEventPage.event.dateEdit.repeatsOn")}</p>
              <DropdownComponent
                type="rounded"
                dropdownOptions={t(
                  "adminEventPage.event.firstToLastDropdownOptions",
                  { returnObjects: true }
                )}
                selectedOption={dynamicDateDayOfWeekNumber.toString()}
                onChange={(value) =>
                  setDynamicDateDayOfWeekNumber(parseInt(value))
                }
              />

              <DropdownComponent
                type="rounded"
                dropdownOptions={t(
                  "adminEventPage.event.dayOfWeekDropdownOptions",
                  { returnObjects: true }
                )}
                selectedOption={dynamicDateDayOfWeek}
                onChange={(value) =>
                  setDynamicDateDayOfWeek(value as DayOfWeek)
                }
              />
              <p>{t("adminEventPage.event.dateEdit.each")}</p>
              <InputComponent
                type="number"
                value={monthlyRepetitionMonth ?? ""}
                onChange={(value) => setMonthlyRepetitionMonth(parseInt(value))}
                isFrameless
                min={1}
                max={99}
              />
              <p>{t("adminEventPage.event.dateEdit.months")}</p>
            </div>
          </>
        );
      case EventIntervall.YEARLY:
        return (
          <>
            <div className="selected-content--dateRepetition-detail--years">
              <CheckboxComponent
                value=""
                checked={specificDateForIntervall}
                onCheck={() => toggleSpecificDateForIntervall(true)}
              />
              <p>{t("adminEventPage.event.dateEdit.repeatsOn")}</p>
              <InputComponent
                type="number"
                value={monthlyRepetitionDay}
                onChange={(value) => setMonthlyRepetitionDay(parseInt(value))}
                isFrameless
                min={1}
                max={28}
              />
              <p>.</p>
              <DropdownComponent
                type="rounded"
                dropdownOptions={t(
                  "adminEventPage.event.monthDropdownOptions",
                  { returnObjects: true }
                )}
                selectedOption={yearlyMonth.toString()}
                onChange={(value) => setYearlyMonth(parseInt(value))}
              />
            </div>
            <div className="selected-content--dateRepetition-detail--months selected-content--dateRepetition-detail--months-borderBottom">
              <CheckboxComponent
                value=""
                checked={!specificDateForIntervall}
                onCheck={() => toggleSpecificDateForIntervall(false)}
              />
              <p>{t("adminEventPage.event.dateEdit.repeatsOn")}</p>
              <DropdownComponent
                type="rounded"
                dropdownOptions={t(
                  "adminEventPage.event.firstToLastDropdownOptions",
                  { returnObjects: true }
                )}
                selectedOption={dynamicDateDayOfWeekNumber.toString()}
                onChange={(value) =>
                  setDynamicDateDayOfWeekNumber(parseInt(value))
                }
              />

              <DropdownComponent
                type="rounded"
                dropdownOptions={t(
                  "adminEventPage.event.dayOfWeekDropdownOptions",
                  { returnObjects: true }
                )}
                selectedOption={dynamicDateDayOfWeek}
                onChange={(value) =>
                  setDynamicDateDayOfWeek(value as DayOfWeek)
                }
              />
              <p>{t("adminEventPage.event.dateEdit.in")}</p>
              <DropdownComponent
                type="rounded"
                dropdownOptions={t(
                  "adminEventPage.event.monthDropdownOptions",
                  { returnObjects: true }
                )}
                selectedOption={yearlyMonth.toString()}
                onChange={(value) => setYearlyMonth(parseInt(value))}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className="event-date-edit">
      <PopupComponent
        open={dateToEdit !== undefined}
        toggleOpen={() => setDateToEdit(undefined)}
        title={t("adminEventPage.event.dateEdit.popup.editDate")}
      >
        <div className="selected-content--dateEdit-wrapper">
          <div className="selected-content--dateEdit-date">
            <p className="selected-content--dateEdit-inputLabel">
              {t("adminEventPage.event.dateEdit.popup.date")}
            </p>
            <InputComponent
              disabled={isLoading}
              value={
                new Date(dateToEdit?.eventDate || new Date())?.toISOString() ??
                ""
              }
              onChange={(value) =>
                setDateToEdit({
                  ...dateToEdit!,
                  eventDate: value
                    ? new Date(value)
                    : new Date(dateToEdit?.eventDate || new Date()),
                })
              }
              type="date"
              isFrameless
            />
          </div>
          <div className="selected-content--dateEdit-timeFrame">
            <p className="selected-content--dateEdit-inputLabel">
              {t("adminEventPage.event.dateEdit.popup.fromUntil")}
            </p>
            <div className="selected-content--dateEdit-timeFrame-inputs">
              <InputComponent
                disabled={isLoading}
                value={dateToEdit?.startTime || ""}
                onChange={(value) =>
                  setDateToEdit({
                    ...dateToEdit!,
                    startTime: value,
                  })
                }
                type="time"
                isFrameless
              />
              <p>-</p>
              <InputComponent
                disabled={isLoading}
                value={dateToEdit?.endTime || ""}
                onChange={(value) =>
                  setDateToEdit({
                    ...dateToEdit!,
                    endTime: value,
                  })
                }
                type="time"
                isFrameless
              />
            </div>
          </div>
        </div>
        <ButtonComponent
          value={t("adminEventPage.event.dateEdit.popup.saveDate")}
          onClick={() => {
            activityCompanion.dateList[dateToEditIndex!] = dateToEdit!;
            setDateToEdit(undefined);
          }}
        />
      </PopupComponent>

      {isDesktop && <h2>{t("adminEventPage.event.steps.step3Title")}</h2>}

      <p className="selected-content--dateRepetition-inputLabel">
        {t("adminEventPage.event.dateEdit.singleDateTitle")}
      </p>
      <div className="selected-content--singleDate">
        <CheckboxComponent
          checked={activityCompanion.dateType === "single"}
          onCheck={() =>
            setActivityCompanion({ ...activityCompanion, dateType: "single" })
          }
          value={t("adminEventPage.event.dateEdit.singleDate")}
        />
        <CheckboxComponent
          checked={activityCompanion.dateType === "multi"}
          onCheck={() =>
            setActivityCompanion({ ...activityCompanion, dateType: "multi" })
          }
          value={t("adminEventPage.event.dateEdit.multiDate")}
        />
        <CheckboxComponent
          checked={activityCompanion.dateType === "continuous"}
          onCheck={() =>
            setActivityCompanion({
              ...activityCompanion,
              dateType: "continuous",
            })
          }
          value={t("adminEventPage.event.dateEdit.continuousDate")}
        />
      </div>
      {activityCompanion.dateType === "continuous" ? (
        <div>
          <div className="selected-content--dateRepetition-detail--startDate">
            <p className="selected-content--dateRepetition-inputLabel">
              {t("adminEventPage.event.dateEdit.startOfContinuous")}
            </p>
            <InputComponent
              disabled={isLoading}
              value={
                activityCompanion.startDate
                  ? new Date(activityCompanion.startDate)?.toISOString()
                  : ""
              }
              onChange={(value) =>
                setActivityCompanion({
                  ...activityCompanion,
                  startDate: value
                    ? new Date(value)
                    : new Date(activityCompanion.startDate || new Date()),
                })
              }
              type="date"
              isFrameless
            />
          </div>
          <div className="selected-content--dateRepetition-detail--endDate">
            <p className="selected-content--dateRepetition-inputLabel">
              {t("adminEventPage.event.dateEdit.endOfContinuous")}
            </p>
            <InputComponent
              disabled={isLoading}
              value={
                activityCompanion.endDate
                  ? new Date(activityCompanion.endDate)?.toISOString()
                  : ""
              }
              onChange={(value) =>
                setActivityCompanion({
                  ...activityCompanion,
                  endDate: value
                    ? new Date(value)
                    : new Date(activityCompanion.endDate || new Date()),
                })
              }
              type="date"
              isFrameless
            />
          </div>
          <div className="selected-content--dateRepetition-detail--intervall-days">
            <p>{t("adminEventPage.event.dateEdit.activeAt")}</p>
            <div className="selected-content--dateRepetition-detail--intervall-days-select">
              {DayOfWeekArray.map((day) => {
                return (
                  <CheckboxComponent
                    key={day}
                    checked={activityCompanion.displayedDays.includes(
                      day as DayOfWeek
                    )}
                    onCheck={() => handleVisibleDaysToggle(day as DayOfWeek)}
                    value={t(`enum.dayOfWeekShort.${day}`)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : activityCompanion.dateType === "multi" ? (
        <div>
          <p className="selected-content--dateRepetition-inputLabel">
            {t("adminEventPage.event.dateEdit.connectedDateTitle")}
          </p>
          <div className="selected-content--dateLink">
            <CheckboxComponent
              checked={activityCompanion.connectedDates === true}
              onCheck={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  connectedDates: true,
                })
              }
              value={t("adminEventPage.event.dateEdit.dateConnected")}
            />
            <CheckboxComponent
              checked={activityCompanion.connectedDates === false}
              onCheck={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  connectedDates: false,
                })
              }
              value={t("adminEventPage.event.dateEdit.dateOnItsOwn")}
            />
          </div>

          <p className="selected-content--dateRepetition-inputLabel">
            {t("adminEventPage.event.dateEdit.eventDateTitle")}
          </p>
          <div className="selected-content--dateRepetition">
            {isDesktop ? (
              <div className="selected-content--dateRepetition-select">
                <CheckboxComponent
                  checked={dateIntervall === EventIntervall.DAILY}
                  onCheck={() => setDateIntervall(EventIntervall.DAILY)}
                  value={t("adminEventPage.event.dateEdit.intervalls.daily")}
                />
                <CheckboxComponent
                  checked={dateIntervall === EventIntervall.WEEKLY}
                  onCheck={() => setDateIntervall(EventIntervall.WEEKLY)}
                  value={t("adminEventPage.event.dateEdit.intervalls.weekly")}
                />
                <CheckboxComponent
                  checked={dateIntervall === EventIntervall.MONTHLY}
                  onCheck={() => setDateIntervall(EventIntervall.MONTHLY)}
                  value={t("adminEventPage.event.dateEdit.intervalls.monthly")}
                />
                <CheckboxComponent
                  checked={dateIntervall === EventIntervall.YEARLY}
                  onCheck={() => setDateIntervall(EventIntervall.YEARLY)}
                  value={t("adminEventPage.event.dateEdit.intervalls.annual")}
                />
              </div>
            ) : (
              <div className="selected-content--dateRepetition-select">
                <DropdownComponent
                  type="rounded"
                  dropdownOptions={t(
                    "adminEventPage.event.intervallDropdownOptions",
                    { returnObjects: true }
                  )}
                  selectedOption={dateIntervall as string}
                  onChange={(value) =>
                    setDateIntervall(value as EventIntervall)
                  }
                />
              </div>
            )}

            <div className="selected-content--dateRepetition-detail">
              <div className="selected-content--dateRepetition-detail--startDate">
                <p className="selected-content--dateRepetition-inputLabel">
                  {t("adminEventPage.event.dateEdit.startOfSeries")}
                </p>
                <InputComponent
                  disabled={isLoading}
                  value={startDate?.toISOString() ?? ""}
                  onChange={(value) =>
                    setStartDate(value ? new Date(value) : startDate)
                  }
                  type="date"
                  isFrameless
                />
              </div>
              {generateAdditionalInputsForIntervalls(dateIntervall)}
              <div className="selected-content--dateRepetition-detail--repetition">
                <CheckboxComponent
                  value=""
                  checked={activityCompanion.dateEndType === "number"}
                  onCheck={() =>
                    setActivityCompanion({
                      ...activityCompanion,
                      dateEndType: "number",
                    })
                  }
                />
                <p>{t("adminEventPage.event.dateEdit.endOfSeriesAfter")}</p>
                <InputComponent
                  value={dateRepetititonNumber ?? "0"}
                  onChange={(value) => setDateRepititionNumber(parseInt(value))}
                  type={"number"}
                  isFrameless
                  max={200}
                />
                <p>{t("adminEventPage.event.dateEdit.dates")}</p>
              </div>
              <div className="selected-content--dateRepetition-detail--endDate">
                <CheckboxComponent
                  value=""
                  checked={activityCompanion.dateEndType === "fix"}
                  onCheck={() =>
                    setActivityCompanion({
                      ...activityCompanion,
                      dateEndType: "fix",
                    })
                  }
                />
                <p>{t("adminEventPage.event.dateEdit.endOfSeries")}</p>
                <InputComponent
                  disabled={isLoading}
                  value={endDate?.toISOString() ?? ""}
                  onChange={(value) =>
                    setEndDate(value ? new Date(value) : endDate)
                  }
                  type="date"
                  isFrameless
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="selected-content--dateRepetition-inputLabel">
            {t("adminEventPage.event.dateEdit.singleDateInput")}
          </p>
          <InputComponent
            disabled={isLoading}
            value={
              activityCompanion.singleDateObject.eventDate
                ? new Date(
                    activityCompanion.singleDateObject.eventDate
                  )?.toISOString()
                : ""
            }
            onChange={(value) =>
              setActivityCompanion({
                ...activityCompanion,
                singleDateObject: {
                  ...activityCompanion.singleDateObject,
                  eventDate: value
                    ? new Date(value)
                    : new Date(
                        activityCompanion.singleDateObject.eventDate ||
                          new Date()
                      ),
                },
              })
            }
            type="date"
            isFrameless
          />
        </div>
      )}
      {activityCompanion.dateType === "multi" && (
        <div className="input-label">
          {t("adminEventPage.event.dateEdit.timeFrameInput")}
        </div>
      )}
      <div className="input-label">
        {t("adminEventPage.event.steps.step3.startEndTime")}
      </div>
      <div className="selected-content--single-time">
        <InputComponent
          disabled={isLoading}
          value={activityCompanion?.singleDateObject?.startTime || ""}
          onChange={(value) =>
            setActivityCompanion({
              ...activityCompanion,
              singleDateObject: {
                ...activityCompanion?.singleDateObject,
                startTime: value,
              },
            })
          }
          type="time"
          isFrameless
        />
        <p>-</p>
        <InputComponent
          disabled={isLoading}
          value={activityCompanion?.singleDateObject?.endTime || ""}
          onChange={(value) =>
            setActivityCompanion({
              ...activityCompanion,
              singleDateObject: {
                ...activityCompanion?.singleDateObject,
                endTime: value,
              },
            })
          }
          type="time"
          isFrameless
        />
      </div>

      {activityCompanion.dateType === "multi" ? (
        <div className="selected-content--date-buttons">
          <ButtonComponent
            value={t("adminEventPage.event.dateEdit.generateDateList")}
            onClick={() =>
              setActivityCompanion({
                ...activityCompanion,
                dateList: generateDateArray(
                  startDate!,
                  endDate!,
                  activityCompanion.dateEndType,
                  dateIntervall,
                  dateRepetititonNumber,
                  activityCompanion.singleDateObject,
                  weekRepetitionNumber,
                  weeklyRepititionDay,
                  dynamicDateDayOfWeek,
                  monthlyRepetitionDay,
                  specificDateForIntervall,
                  monthlyRepetitionMonth,
                  dynamicDateDayOfWeekNumber,
                  yearlyMonth
                ),
              })
            }
          />
          <ButtonComponent
            disabled={activityCompanion.dateList.length < 1}
            value={t("adminEventPage.event.dateEdit.addDate")}
            onClick={() =>
              setActivityCompanion({
                ...activityCompanion,
                dateList: addNewEntryToDateArray(activityCompanion.dateList),
              })
            }
          />
          {activityCompanion.dateList.length > 0 ? (
            <ButtonComponent
              value={t("adminEventPage.event.steps.step3.emptyList")}
              onClick={() =>
                setActivityCompanion({
                  ...activityCompanion,
                  dateList: [],
                })
              }
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="selected-content--date-table">
        {generateMultiDateTable(
          activityCompanion,
          generateDateRows(
            activityCompanion.dateList,
            deleteEntryFromDateArray,
            setDateToEdit,
            setDateToEditIndex,
            copyEntryFromDateArray
          )
        )}
      </div>
    </div>
  );
};

export default ActivityStep3;
