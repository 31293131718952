import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  checkIfMultipleDatesArePresent,
  extendDateList,
  extendSingleDateObjectWithShowDate,
  generateCorrectDeadlineString,
  generateShowDateTableRow,
} from "../../../utils/event/EventUtils";
import { ActivityEditCompanion } from "../EventEditBoxComponent.types";
import { DayOfWeekArray } from "../../../utils/event/Event.types";
import { DayOfWeek } from "../../../utils/user/User.types";

interface Step5Props {
  isDesktop: boolean;
  isLoading: boolean | undefined;
  activityCompanion: ActivityEditCompanion;
  setActivityCompanion(activityCompanion: ActivityEditCompanion): void;
  stepCompletion?: boolean;
}

const ActivityStep5: React.FC<Step5Props> = ({
  isDesktop,
  isLoading,
  activityCompanion,
  setActivityCompanion,
}) => {
  const { t } = useTranslation();
  const [localDeadlineAmount, setLocalDeadlineAmount] = useState<
    number | undefined
  >(activityCompanion.deadlineAmount || undefined);
  const [localDeadlineType, setLocalDeadlineType] = useState<
    "day" | "week" | "month" | "year" | undefined
  >(activityCompanion.deadlineType || undefined);

  /**
   * Helper to set or delete entries for activeDays in actions
   * @param selectedDayOfWeek DayOfWeek to be added or deleted from array
   */
  const handleActiveDaysToggle = (selectedDayOfWeek: DayOfWeek): void => {
    let localDayOfWeekArray = [...(activityCompanion?.activeDays || [])];
    if (localDayOfWeekArray.includes(selectedDayOfWeek)) {
      localDayOfWeekArray = localDayOfWeekArray.filter((day) => {
        return day !== selectedDayOfWeek;
      });
    } else {
      localDayOfWeekArray.push(selectedDayOfWeek);
    }
    setActivityCompanion({
      ...activityCompanion,
      activeDays: localDayOfWeekArray,
    });
  };

  /**
   * this useEffect update the Edit Companion for deadline
   */
  useEffect(() => {
    if (
      (localDeadlineAmount === 0 || !!localDeadlineAmount) &&
      !!localDeadlineType
    ) {
      setActivityCompanion({
        ...activityCompanion,
        singleDateObject: extendSingleDateObjectWithShowDate(
          activityCompanion,
          localDeadlineType!,
          localDeadlineAmount!
        ),
        deadlineAmount: localDeadlineAmount,
        deadlineType: localDeadlineType,
      });
    } else {
      setActivityCompanion({
        ...activityCompanion,
        deadlineAmount: localDeadlineAmount,
        deadlineType: localDeadlineType,
      });
    }
    //eslint-disable-next-line
  }, [localDeadlineAmount, localDeadlineType]);

  return (
    <>
      {isDesktop && <h2>{t("adminEventPage.event.steps.step5Title")}</h2>}

      <div className="input-label">
        {t("adminEventPage.event.steps.step5.dateStartShow")}
      </div>

      <div className="selected-content--show-date">
        <CheckboxComponent
          disabled={isLoading}
          value={t("adminEventPage.event.steps.step5.now")}
          checked={activityCompanion.visibleFrom === "now"}
          onCheck={() =>
            setActivityCompanion({ ...activityCompanion, visibleFrom: "now" })
          }
        />
        <CheckboxComponent
          disabled={isLoading}
          value={t("adminEventPage.event.steps.step5.visibleFrom")}
          checked={activityCompanion.visibleFrom === "date"}
          onCheck={() =>
            setActivityCompanion({ ...activityCompanion, visibleFrom: "date" })
          }
        />
        <CheckboxComponent
          disabled={isLoading}
          value={t("adminEventPage.event.steps.step5.deadline")}
          checked={activityCompanion.visibleFrom === "deadline"}
          onCheck={() =>
            setActivityCompanion({
              ...activityCompanion,
              visibleFrom: "deadline",
            })
          }
        />
      </div>
      {activityCompanion.visibleFrom === "date" && (
        <div className="selected-content--show-date--input">
          <div className="input-label">
            {t("adminEventPage.event.steps.step5.visibleFromInput")}
          </div>
          <InputComponent
            disabled={isLoading}
            isFrameless
            value={
              activityCompanion.singleDateObject.showDate
                ? new Date(
                    activityCompanion.singleDateObject.showDate
                  ).toISOString()
                : ""
            }
            type="date"
            onChange={(value) =>
              setActivityCompanion({
                ...activityCompanion,
                singleDateObject: {
                  ...activityCompanion.singleDateObject,
                  showDate: value
                    ? new Date(value)
                    : activityCompanion.event.showDate,
                },
              })
            }
          />
        </div>
      )}
      {activityCompanion.visibleFrom === "deadline" && (
        <div>
          <div className="input-label">
            {t("adminEventPage.event.steps.step5.deadlineInput")}
          </div>
          <div className="selected-content--deadline-input">
            <div className="selected-content--deadline-input--left-side">
              {isDesktop ? (
                <>
                  <CheckboxComponent
                    checked={activityCompanion.deadlineType === "day"}
                    value={t("adminEventPage.event.steps.step5.days")}
                    onCheck={() => setLocalDeadlineType("day")}
                  />
                  <CheckboxComponent
                    checked={activityCompanion.deadlineType === "week"}
                    value={t("adminEventPage.event.steps.step5.weeks")}
                    onCheck={() => setLocalDeadlineType("week")}
                  />
                  <CheckboxComponent
                    checked={activityCompanion.deadlineType === "month"}
                    value={t("adminEventPage.event.steps.step5.months")}
                    onCheck={() => setLocalDeadlineType("month")}
                  />
                  <CheckboxComponent
                    checked={activityCompanion.deadlineType === "year"}
                    value={t("adminEventPage.event.steps.step5.years")}
                    onCheck={() => setLocalDeadlineType("year")}
                  />
                </>
              ) : (
                <>
                  <DropdownComponent
                    hideClearIcon
                    dropdownOptions={t(
                      "adminEventPage.event.deadlineDropdownOptions",
                      { returnObjects: true }
                    )}
                    onChange={(value: "day" | "week" | "month" | "year") =>
                      setLocalDeadlineType(value)
                    }
                    selectedOption={activityCompanion?.deadlineType || "day"}
                  />
                </>
              )}
            </div>
            <div className="selected-content--deadline-input--right-side">
              <div className="selected-content--intervall-amount-selector">
                <p>{t("adminEventPage.event.steps.step5.visibility")}</p>
                <InputComponent
                  isFrameless
                  min={0}
                  type="number"
                  value={activityCompanion.deadlineAmount || 0}
                  onChange={(value) => setLocalDeadlineAmount(parseInt(value))}
                />
                <p>
                  {generateCorrectDeadlineString(
                    activityCompanion.deadlineType
                  )}
                </p>
              </div>
              {checkIfMultipleDatesArePresent(activityCompanion) &&
              activityCompanion.dateType === "multi" &&
              activityCompanion.visibleFrom === "deadline" ? (
                <div>
                  <ButtonComponent
                    value={t(
                      "adminEventPage.event.steps.step5.generateDateList"
                    )}
                    onClick={() =>
                      setActivityCompanion({
                        ...activityCompanion,
                        dateList: extendDateList(activityCompanion),
                      })
                    }
                  />
                </div>
              ) : (
                <div>
                  {t("adminEventPage.event.steps.step5.dateVisibleFrom")}

                  {activityCompanion.singleDateObject.showDate
                    ? new Date(
                        activityCompanion.singleDateObject?.showDate
                      )?.toLocaleDateString("de")
                    : ""}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {activityCompanion.visibleFrom === "deadline" &&
        activityCompanion.dateType === "multi" && (
          <div className="selected-content--showDate-table">
            <TableComponent
              rows={generateShowDateTableRow(
                activityCompanion,
                setActivityCompanion
              )}
            />
          </div>
        )}
      <CheckboxComponent
        checked={activityCompanion?.onlyVisibleOnSpecificDays || false}
        onCheck={() => {
          setActivityCompanion({
            ...activityCompanion,
            onlyVisibleOnSpecificDays:
              !!activityCompanion.onlyVisibleOnSpecificDays
                ? !activityCompanion.onlyVisibleOnSpecificDays
                : true,
          });
        }}
        value={t("adminEventPage.event.dateEdit.onlyVisibleCertainDays")}
      />
      {activityCompanion?.onlyVisibleOnSpecificDays ? (
        <div className="selected-content--dateRepetition-detail--intervall-days">
          <p>{t("adminEventPage.event.dateEdit.visibleAt")}</p>
          <div className="selected-content--dateRepetition-detail--intervall-days-select">
            {DayOfWeekArray.map((day) => {
              return (
                <CheckboxComponent
                  key={day}
                  checked={
                    !!activityCompanion?.activeDays
                      ? activityCompanion?.activeDays.includes(day as DayOfWeek)
                      : false
                  }
                  onCheck={() => handleActiveDaysToggle(day as DayOfWeek)}
                  value={t(`enum.dayOfWeekShort.${day}`)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default ActivityStep5;
