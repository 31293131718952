import { EventEditBoxComponentProps } from "./EventEditBoxComponent.types";
import "../../styles/EventEditStyles.scss";
import ActivityStep2 from "./subcomponents/ActivityStep2";
import ActivityStep1 from "./subcomponents/ActivityStep1";
import ActivityStep3 from "./subcomponents/ActivityStep3";
import ActivityStep4 from "./subcomponents/ActivityStep4";
import ActivityStep5 from "./subcomponents/ActivityStep5";
import { useEffect } from "react";

const EventEditBoxComponent: React.FC<EventEditBoxComponentProps> = ({
  index,
  isDesktop = false,
  activityCompanion,
  setActivityCompanion,
  provider,
  isLoading,
  stepCompletion,
  isEdit,
}) => {
  /**
   *
   */
  useEffect(() => {
    if (index < 1 || index > 5) return;
    let currentBox = document.getElementById(`box-${index}`);
    currentBox?.scrollIntoView({ behavior: "smooth" });
  }, [index]);

  return (
    <div className="selected-content">
      {index === 1 && (
        <>
          <div className="scroll-box" id="box-1" />
          <ActivityStep1
            isDesktop={isDesktop}
            activityCompanion={activityCompanion}
            setActivityCompanion={setActivityCompanion}
            stepCompletion={stepCompletion}
            isEdit={isEdit}
          />
        </>
      )}
      {index === 2 && (
        <>
          <div className="scroll-box" id="box-2" />
          <ActivityStep2
            provider={provider}
            activityCompanion={activityCompanion}
            isDesktop={isDesktop}
            isLoading={isLoading!}
            setActivityCompanion={setActivityCompanion}
            stepCompletion={stepCompletion}
          />
        </>
      )}
      {index === 3 && (
        <>
          <div className="scroll-box" id="box-3" />
          <ActivityStep3
            isDesktop={isDesktop}
            isLoading={isLoading}
            activityCompanion={activityCompanion}
            setActivityCompanion={setActivityCompanion}
            stepCompletion={stepCompletion}
          />
        </>
      )}
      {index === 4 && (
        <>
          <div className="scroll-box" id="box-4" />
          <ActivityStep4
            provider={provider}
            activityCompanion={activityCompanion}
            setActivityCompanion={setActivityCompanion}
            isDesktop={isDesktop}
            isLoading={isLoading!}
            stepCompletion={stepCompletion}
          />
        </>
      )}
      {index === 5 && (
        <>
          <div className="scroll-box" id="box-5" />
          <ActivityStep5
            isDesktop={isDesktop}
            isLoading={isLoading}
            activityCompanion={activityCompanion}
            setActivityCompanion={setActivityCompanion}
            stepCompletion={stepCompletion}
          />
        </>
      )}
    </div>
  );
};

export default EventEditBoxComponent;
