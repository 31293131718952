import {
  AdminLayoutComponent,
  CheckboxComponent,
  DropdownComponent,
  DropdownOption,
  generateNotification,
  LoaderComponent,
  NotificationTypes,
  PopupComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useState from "react-usestateref";
import { ReactComponent as AddIcon } from "../assets/icons/add.svg";
import DateStatusEdit from "../components/datestatus/DateStatusEdit";
import {
  ActivityEditCompanion,
  createEmptyActivityEditCompanion,
} from "../components/newEventEdit/EventEditBoxComponent.types";
import ActivityStep3 from "../components/newEventEdit/subcomponents/ActivityStep3";
import ProgressBarComponent from "../components/progressbar/ProgressBarComponent";
import "../styles/AdminActivity.style.scss";
import { Action } from "../utils/action/Action.types";
import {
  deleteAction,
  fetchAllActions,
  fetchAllActionsForProvider,
  getProviderNameFroIdNameMap,
} from "../utils/action/ActionUtils";
import { ActivityType, PopupType } from "../utils/activity/Activity.types";
import {
  copyActivity,
  createCorrectEncoreTextForType,
  generateActivityDropdownList,
  generateCombinedActivityTableRows,
  generateCorrectButtonBottomsForCombinedPopupType,
  generateSpecificCombinedPopupContent,
} from "../utils/activity/ActivityUtils";
import { useAxios } from "../utils/AxiosUtil";
import { Event } from "../utils/event/Event.types";
import {
  deleteEvent,
  fetchAllEvents,
  fetchAllEventsForProvider,
} from "../utils/event/EventUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import { IdName, Provider, UserRole } from "../utils/user/User.types";
import {
  fetchAllProvider,
  fetchUserById,
  getCorrectProviderFromListBySlugId,
} from "../utils/user/UserUtils";
import { ConfigContext, UserContext } from "./App";

interface ActivityOverviewPageProps {
  userRole: UserRole;
}

const ActivityOverviewPage: React.FC<ActivityOverviewPageProps> = ({
  userRole,
}) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const [loadedEvents, setLoadedEvents] = useState<Event[]>([]);
  const [loadedActions, setLoadedActions] = useState<Action[]>([]);
  const [showOldEvents, toggleOldEvents] = useState<boolean>(false);
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isCopyLoading, toggleCopyLoading] = useState<boolean>(false);
  const [isCopyFinished, toggleCopyFinished] = useState<boolean>(false);
  const [mapIdAndName, setMapIdAndName] = useState<IdName[]>([]);
  const [localProvider, setLocalProvider] = useState<Provider>();
  const [providerList, setProviderList] = useState<Provider[]>([]);
  const [providerSelectionPopup, toggleProviderSelectionPopup] =
    useState<boolean>(false);
  const [selectedAcitivty, setSelectedAcitivty] = useState<Action | Event>();
  const [selectedAcitivtyType, setSelectedAcitivtyType] =
    useState<ActivityType>();
  const [selectedAcitivtyEditCompanion, setSelectedAcitivtyEditCompanion] =
    useState<ActivityEditCompanion>(createEmptyActivityEditCompanion());
  const [selectedActivityForDelayCancel, setSelectedActivityForDelayCancel] =
    useState<Action | Event>();
  const [
    currentProgressNumber,
    setCurrentProgressNumber,
    currentProgressNumberRef,
  ] = useState<number>(0);
  const [currentCombinedActivityList, setCurrentCombinedActivityList] =
    useState<(Action | Event)[]>([]);
  const [currentCombinedPopupType, setCurrentCombinedPopupType] =
    useState<PopupType>();
  const [currentActivity, setCurrentActivity] = useState<Action | Event>();
  const [combinedDeletePopup, toggleCombinedDeletePopup] =
    useState<boolean>(false);

  const [combinedDeleteSure, toggleCombinedDeleteSure] =
    useState<boolean>(false);
  const [isDeleteLoading, toggleDeleteLoading] = useState<boolean>(false);
  const [currentActivityType, setCurrentActivityType] =
    useState<ActivityType>();

  /**
   * this useeffect fetches all events for the platformm and
   * the correct name for the provider by slugid as well as all the providers for event creation
   */
  useEffect(() => {
    if (!axios || !user) return;

    switch (userRole) {
      case UserRole.ADMIN:
        Promise.all([
          fetchAllEvents(axios),
          fetchAllActions(axios),
          fetchAllProvider(axios),
        ]).then(([fetchedEvents, fetchedActions, providers]) => {
          setProviderList(providers);
          setLoadedActions(fetchedActions);
          setLoadedEvents(fetchedEvents);
          let local: IdName[] = [];
          providers.forEach((provider) => {
            let currentEntry: IdName = {
              id: provider.slugid,
              name: provider.name,
            };
            local.push(currentEntry);
          });
          setMapIdAndName(local);
        });
        break;

      case UserRole.PROVIDER:
        fetchUserById(axios, user.id!, UserRole.PROVIDER).then(
          (loadedProvider) => {
            setLocalProvider(loadedProvider as Provider);
            Promise.all([
              fetchAllEventsForProvider(
                axios,
                (loadedProvider as Provider).slugid
              ),
              fetchAllActionsForProvider(
                axios,
                (loadedProvider as Provider).slugid
              ),
            ]).then(([fetchedEvents, fetchedActions]) => {
              setLoadedActions(fetchedActions);
              setLoadedEvents(fetchedEvents);
            });
          }
        );
        break;

      default:
        history.push("/");
        break;
    }
    // eslint-disable-next-line
  }, [axios, userRole, user]);

  /**
   * this useEffect updates all needed activity edit data for
   * further modification
   */
  useEffect(() => {
    if (!selectedAcitivty) return;

    let localActivityEditCompanion: ActivityEditCompanion | undefined =
      undefined;

    switch (selectedAcitivtyType) {
      case ActivityType.ACTION:
        localActivityEditCompanion = createEmptyActivityEditCompanion(
          undefined,
          selectedAcitivty as Action,
          selectedAcitivty.providerId
        );
        localActivityEditCompanion.category = (
          selectedAcitivty as Action
        ).categoryItem;
        localActivityEditCompanion.activityType = ActivityType.ACTION;
        //Following values in action get later overwritten by values in event
        localActivityEditCompanion.event.name = selectedAcitivty.name;
        localActivityEditCompanion.event.location = selectedAcitivty.location;
        localActivityEditCompanion.event.description =
          selectedAcitivty.description;
        localActivityEditCompanion.event.flyer =
          selectedAcitivty.flyer || undefined;
        break;

      case ActivityType.EVENT:
        localActivityEditCompanion = createEmptyActivityEditCompanion(
          selectedAcitivty as Event,
          undefined,
          selectedAcitivty.providerId
        );
        localActivityEditCompanion.category = (
          selectedAcitivty as Event
        ).category;
        localActivityEditCompanion.activityType = ActivityType.EVENT;
        break;
    }

    if (!localActivityEditCompanion) return;
    localActivityEditCompanion.activityType = selectedAcitivtyType;

    localActivityEditCompanion.providerId = selectedAcitivty.providerId;
    localActivityEditCompanion.mainTag = selectedAcitivty.mainTag;
    localActivityEditCompanion.detailTags = selectedAcitivty.detailTags;
    localActivityEditCompanion.customTags = selectedAcitivty.customTags;
    localActivityEditCompanion.targetAudiences =
      selectedAcitivty.targetAudiences;

    localActivityEditCompanion.singleDateObject.showDate =
      selectedAcitivty.showDate || undefined;
    localActivityEditCompanion.image = selectedAcitivty.image || undefined;
    setSelectedAcitivtyEditCompanion(localActivityEditCompanion);
    // eslint-disable-next-line
  }, [selectedAcitivty]);

  /**
   * Helper to get dropdown options for providers with a optional filter
   *
   * @param filter optional filter function
   *
   * @returns a list of dropdown options
   */
  const createProviderDropdownEntries = (
    filter: (
      value: Provider,
      index: number,
      array: Provider[]
    ) => boolean = () => true
  ): DropdownOption[] => {
    return providerList.filter(filter).map((provider) => ({
      label: provider.name,
      value: provider.slugid,
    }));
  };

  /**
   * Helper to delete a list of activitiesat once and handle success/failure and execute given methods after each deletion and in the end
   * @param activityList Array of activities to be deleted
   * @param onFinish Method to be executed when finished
   * @param onDelete  Method to be executed after each successfull deletion
   */
  const deleteCombinedActivities = async (
    activityList: (Action | Event)[]
  ): Promise<void> => {
    let deletionFailed: boolean = false;
    let deletedIds: string[] = [];
    for (let index = 0; index < activityList.length; index++) {
      if (!activityList[index].id) continue;

      switch (true) {
        case currentActivityType === ActivityType.EVENT:
          let eventDeletionSuccess: boolean = await deleteEvent(
            axios,
            activityList[index].id!
          );

          if (eventDeletionSuccess) deletedIds.push(activityList[index].id!);
          else deletionFailed = true;
          break;

        case currentActivityType === ActivityType.ACTION:
          let actionDeletionSuccess: boolean = await deleteAction(
            axios,
            activityList[index].id!
          );

          if (actionDeletionSuccess) deletedIds.push(activityList[index].id!);
          else deletionFailed = true;
          break;

        default:
          deletionFailed = true;
          return;
      }

      setCurrentProgressNumber(currentProgressNumberRef.current + 1);
    }

    if (deletionFailed)
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.title.error.error"),
        t("notification.content.error.activitiesDelete")
      );
    else
      generateNotification(
        NotificationTypes.SUCCESS,
        t("notification.title.success.deleteSuccessful"),
        t("notification.content.success.activitiesDelete")
      );

    //clear activities from list
    switch (true) {
      case currentActivityType === ActivityType.EVENT:
        setLoadedEvents([
          ...loadedEvents.filter((item) => !deletedIds.includes(item.id ?? "")),
        ]);
        break;

      case currentActivityType === ActivityType.ACTION:
        setLoadedActions([
          ...loadedActions.filter(
            (item) => !deletedIds.includes(item.id ?? "")
          ),
        ]);
        break;

      default:
        break;
    }

    setCurrentProgressNumber(0);
    toggleCombinedDeletePopup(false);
    setCurrentCombinedPopupType(undefined);
    toggleDeleteLoading(false);
  };

  /**
   * Helper to handle process of deleting combined activities
   */
  const handleCombinedDeleteButtonClick = (): void => {
    setCurrentProgressNumber(0);
    toggleDeleteLoading(true);
    deleteCombinedActivities(currentCombinedActivityList);
    toggleCombinedDeleteSure(false);
  };

  /**
   * Helper to remove an event from the server and from the local list
   * @param activityId id to remove
   * @param type type of currently acitivity to remove
   */
  const removeActivity = (activityId: string, type: ActivityType): void => {
    toggleLoading(true);
    switch (type) {
      case ActivityType.EVENT:
        deleteEvent(axios, activityId).then((success) => {
          if (success) {
            setLoadedEvents([
              ...loadedEvents.filter((item) => item.id !== activityId),
            ]);
            generateNotification(
              NotificationTypes.SUCCESS,
              t("notification.title.success.deleteSuccessful"),
              t("notification.content.success.eventDelete")
            );
          } else {
            generateNotification(
              NotificationTypes.ERROR,
              t("notification.title.error.error"),
              t("notification.content.error.eventDelete")
            );
          }
        });
        break;
      case ActivityType.ACTION:
        deleteAction(axios, activityId).then((success) => {
          if (success) {
            setLoadedActions([
              ...loadedActions.filter((item) => item.id !== activityId),
            ]);
            generateNotification(
              NotificationTypes.SUCCESS,
              t("notification.title.success.deleteSuccessful"),
              t("notification.content.success.actionDelete")
            );
          } else {
            generateNotification(
              NotificationTypes.ERROR,
              t("notification.title.error.error"),
              t("notification.content.error.actionDelete")
            );
          }
        });
        break;
      default:
        break;
    }
    toggleLoading(false);
  };

  /**
   * Helper to execute call of eventEditPage with correct state
   * @param event event that contains all needed information
   * @param action action that contains all needed information
   */
  const updateAcitivityOnNewPage = (event?: Event, action?: Action): void => {
    history.push("/config/event/edit", {
      providerSlug: event?.providerId || action?.providerId,
      eventId: event?.slugId,
      actionId: action?.slugId,
      isEdit: true,
    });
  };
  /**
   * Helper to set List of combined activites with the same name and to open popup to select date
   * @param activities list of actions or events
   */
  const selectCombinedActivities = (
    activities: (Event | Action)[],
    activityType: ActivityType,
    type: PopupType
  ): void => {
    setCurrentCombinedPopupType(type);
    setCurrentActivityType(activityType);
    setCurrentCombinedActivityList(activities ?? []);
  };

  /**
   * Helper to set all states to undefined when popup will be closed
   */
  const closeCombinedPopup = (): void => {
    setCurrentActivity(undefined);
    setCurrentCombinedPopupType(undefined);
  };

  /**
   * Helper to handle buttonClick in popup of combined Activities
   */
  const handleSpecificCombinedButton = (): void => {
    const currentIsEvent = currentActivityType === ActivityType.EVENT;
    switch (currentCombinedPopupType) {
      case PopupType.DELETE:
        removeActivity(
          currentActivity!.id!,
          currentIsEvent ? ActivityType.EVENT : ActivityType.ACTION
        );
        setCurrentActivity(undefined);
        setCurrentCombinedPopupType(undefined);
        break;

      case PopupType.DETAIL:
        setSelectedActivityForDelayCancel(currentActivity);
        break;

      case PopupType.DUPLICATE:
        toggleCopyLoading(false);
        setSelectedAcitivty(currentActivity);
        setSelectedAcitivtyType(
          currentIsEvent ? ActivityType.EVENT : ActivityType.ACTION
        );
        break;

      case PopupType.EDIT:
        if (currentIsEvent) updateAcitivityOnNewPage(currentActivity as Event);
        else updateAcitivityOnNewPage(undefined, currentActivity as Action);
        break;

      default:
        return;
    }
  };

  /**
   *
   */
  const addCorrectActivity = (): void => {
    switch (userRole) {
      case UserRole.ADMIN:
        toggleProviderSelectionPopup(true);
        break;

      case UserRole.PROVIDER:
        if (!!localProvider?.slugid) history.push("/");
        history.push("/config/event/create", {
          providerSlug: localProvider?.slugid,
        });
        break;

      case UserRole.NONE:
      default:
        break;
    }
  };

  /**
   * Helper to start the copy process
   */
  const handleStartCopyProcessOrFinsih = () => {
    if (isCopyLoading) {
      if (!isCopyFinished) return;
      toggleCopyLoading(false);
      setSelectedAcitivty(undefined);
      setSelectedAcitivtyEditCompanion(createEmptyActivityEditCompanion());
      setCurrentProgressNumber(0);
    } else {
      toggleCopyLoading(true);
      toggleCopyFinished(false);
      copyActivity(
        axios,
        selectedAcitivtyEditCompanion,
        () => toggleCopyFinished(true),
        (activity: Event | Action) => {
          switch (selectedAcitivtyType) {
            case ActivityType.ACTION:
              setLoadedActions([...loadedActions, activity as Action]);
              break;
            case ActivityType.EVENT:
              setLoadedEvents([...loadedEvents, activity as Event]);
              break;
          }
          setCurrentProgressNumber(currentProgressNumberRef.current + 1);
        }
      );
    }
  };

  return (
    <>
      {isLoading && <LoaderComponent isFullscreen />}

      <AdminLayoutComponent
        {...useNavLayout(
          userRole === UserRole.PROVIDER
            ? CurrentPage.PROVIDER_ACTIVITY
            : CurrentPage.ADMIN_ACTIVITY
        )}
      >
        <DateStatusEdit
          activityToEdit={selectedActivityForDelayCancel}
          setActivityToEdit={setSelectedActivityForDelayCancel}
        />
        <PopupComponent
          open={!!currentCombinedPopupType}
          toggleOpen={() => {
            setCurrentActivity(undefined);
            setCurrentCombinedPopupType(undefined);
          }}
          title={t("adminEventPage.selectDate")}
          bottomButtons={generateCorrectButtonBottomsForCombinedPopupType(
            currentCombinedPopupType!,
            closeCombinedPopup,
            handleSpecificCombinedButton,
            currentActivity
          )}
        >
          <p>{createCorrectEncoreTextForType(currentCombinedPopupType!)}</p>
          <DropdownComponent
            dropdownOptions={generateActivityDropdownList(
              currentCombinedActivityList || [],
              currentActivityType!
            )}
            selectedOption={currentActivity?.id || ""}
            onChange={(value) => {
              setCurrentActivity(
                currentCombinedActivityList.filter((activity) => {
                  return activity.id === value;
                })[0] || undefined
              );
            }}
          />
          {generateSpecificCombinedPopupContent(currentCombinedPopupType!, () =>
            toggleCombinedDeletePopup(!combinedDeletePopup)
          )}
        </PopupComponent>
        <PopupComponent
          open={combinedDeletePopup}
          toggleOpen={toggleCombinedDeletePopup}
          title={t("adminEventPage.deleteAllTitle")}
          bottomButtons={[
            {
              value: t("adminEventPage.buttons.cancel"),
              onClick: () => {
                toggleCombinedDeleteSure(false);
                toggleCombinedDeletePopup(false);
              },
            },
            {
              disabled: !combinedDeleteSure,
              value: t("adminEventPage.buttons.delete"),
              onClick: handleCombinedDeleteButtonClick,
            },
          ]}
        >
          <div>
            <p>
              {t("adminEventPage.deleteActivities", {
                replace: { name: currentCombinedActivityList?.[0]?.name || "" },
              })}
            </p>
            <CheckboxComponent
              checked={combinedDeleteSure}
              onCheck={toggleCombinedDeleteSure}
              value={t("adminEventPage.sureToDeleteAll")}
            />
          </div>
          {isDeleteLoading ? (
            <>
              <ProgressBarComponent
                backgroundColor={appConfig?.highlightColor}
                current={currentProgressNumber}
                total={
                  (!!currentCombinedActivityList
                    ? currentCombinedActivityList.length
                    : 1) || 1
                }
              />
              <div className="progress-text">
                <p>
                  {t("adminEventPage.progress.progress")}
                  {`${currentProgressNumber}/${
                    (!!currentCombinedActivityList
                      ? currentCombinedActivityList.length
                      : 1) || 1
                  }`}
                  {t("adminEventPage.progress.activities")}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </PopupComponent>
        <PopupComponent
          open={!!selectedAcitivty}
          toggleOpen={() => {
            toggleCopyLoading(false);
            setSelectedAcitivty(undefined);
          }}
          title={t("adminEventPage.duplicatePopupTitle")}
          bottomButtons={[
            {
              value: isCopyLoading
                ? t("adminEventPage.event.close")
                : t("adminEventPage.event.save"),
              onClick: handleStartCopyProcessOrFinsih,
            },
          ]}
        >
          <div className="copy-popup--name">{selectedAcitivty?.name}</div>
          <div className="copy-popup--header">
            {userRole === UserRole.ADMIN && (
              <div className="name">
                {getProviderNameFroIdNameMap(
                  mapIdAndName,
                  selectedAcitivty?.providerId ?? ""
                )}
              </div>
            )}
            <div className="type">
              {t(`enum.activityType.${selectedAcitivtyType}`)}
            </div>
          </div>

          {isCopyLoading ? (
            <>
              <ProgressBarComponent
                backgroundColor={appConfig?.highlightColor}
                current={currentProgressNumber}
                total={
                  (!selectedAcitivtyEditCompanion?.connectedDates
                    ? selectedAcitivtyEditCompanion?.dateList.length
                    : 1) || 1
                }
              />
              <div className="progress-text">
                <p>
                  {t("adminEventPage.progress.progress")}
                  {`${currentProgressNumber}/${
                    (!selectedAcitivtyEditCompanion?.connectedDates
                      ? selectedAcitivtyEditCompanion?.dateList.length
                      : 1) || 1
                  }`}
                  {t("adminEventPage.progress.activities")}
                </p>
              </div>
            </>
          ) : (
            <>
              <ActivityStep3
                activityCompanion={selectedAcitivtyEditCompanion}
                isDesktop
                isLoading={isCopyLoading}
                setActivityCompanion={setSelectedAcitivtyEditCompanion}
              />
            </>
          )}
        </PopupComponent>
        <PopupComponent
          open={providerSelectionPopup}
          toggleOpen={toggleProviderSelectionPopup}
          title={t("adminEventPage.event.selectedProvider")}
          bottomButtons={[
            {
              value: t("adminEventPage.event.select"),
              disabled: !localProvider,
              onClick: () =>
                history.push("/config/event/create", {
                  providerSlug: localProvider?.slugid,
                }),
            },
          ]}
        >
          <DropdownComponent
            colors={{
              hover: {
                bgColor: appConfig?.highlightColor,
                fontColor: appConfig?.fontColor,
              },
            }}
            searchable
            dropdownOptions={createProviderDropdownEntries()}
            selectedOption={localProvider?.slugid || ""}
            onChange={(slugId) => {
              setLocalProvider(
                getCorrectProviderFromListBySlugId(providerList, slugId)
              );
            }}
            onClickExecute={(value) => {
              value &&
                setLocalProvider(
                  getCorrectProviderFromListBySlugId(providerList, value)
                );
            }}
            onKeyUp={(key, activeIndex) => {
              if (key.key.toLocaleLowerCase() === "enter") {
                activeIndex && setLocalProvider(providerList[activeIndex]);
              }
            }}
          />
        </PopupComponent>
        <div
          style={{ background: appConfig?.highlightColor }}
          className="default-page-headline"
        >
          <h1>{t("adminEventPage.title")}</h1>
        </div>
        <div className="admin-activity-wrapper default-page-wrapper">
          <div className="admin-activity--add">
            <AddIcon onClick={addCorrectActivity} />
            <h3>{t("adminEventPage.createNewEvent")}</h3>
          </div>
          {!isLoading && (
            <>
              <CheckboxComponent
                checked={showOldEvents}
                onCheck={() => toggleOldEvents(!showOldEvents)}
                value={t("providerEventsConfigurationPage.showPastEvents")}
              />
              <TableComponent
                searchable
                key={loadedEvents.length + mapIdAndName.length}
                maxPageAmount={20}
                initialSort={user?.role === UserRole.ADMIN ? 2 : 1}
                header={t(
                  userRole === UserRole.ADMIN
                    ? "adminEventPage.event.adminTableHeader"
                    : "adminEventPage.event.providerTableHeader",
                  {
                    returnObjects: true,
                  }
                )}
                rows={generateCombinedActivityTableRows(
                  loadedEvents,
                  loadedActions,
                  (activity: Action | Event, type: ActivityType) => {
                    toggleCopyLoading(false);
                    setSelectedAcitivty(activity);
                    setSelectedAcitivtyType(type);
                  },
                  updateAcitivityOnNewPage,
                  selectCombinedActivities,
                  setSelectedActivityForDelayCancel,
                  removeActivity,
                  showOldEvents,
                  userRole === UserRole.ADMIN,
                  mapIdAndName
                )}
              />
            </>
          )}
        </div>
      </AdminLayoutComponent>
    </>
  );
};

export default ActivityOverviewPage;
